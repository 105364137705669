<template>
  <div class="allianceInfo">
    <el-form
      :model="allianceInfo"
      :rules="rules"
      :show-message="false"
      ref="ruleForm"
      size="small"
      label-width="130px"
    >
      <el-row :gutter="20">
        <el-col :span="10">
          <div class="grid-content">
            <SubTitle>联盟信息</SubTitle>
            <div class="item-content">
              <el-form-item label="联盟名称：" prop="name">
                <el-input
                  v-model="allianceInfo.name"
                  clearable
                  placeholder="请输入联盟名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在地区：" prop="areaId">
                <SelectArea
                  checkStrictly
                  size="small"
                  class="area"
                  :areaId="allianceInfo.areaId"
                  placeholder="请选择地区"
                  @change="areaChange"
                />
              </el-form-item>
              <el-form-item label="联盟简介：" prop="introduction">
                <el-input
                  type="textarea"
                  placeholder="请输入联盟简介"
                  v-model="allianceInfo.introduction"
                  maxlength="200"
                  :autosize="{ minRows: 6, maxRows: 6 }"
                  show-word-limit
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item label="联盟图标：" prop="logoUrl">
                <UploadFileImg
                  :maxSize="1"
                  listType="picture-card"
                  :limit="1"
                  :fileList="imgList"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  tip="图片大小2M以内，建议尺寸比例1:1"
                  @getFileList="(fileList) => getImgFileList(fileList)"
                />
              </el-form-item>
            </div>

            <SubTitle>联盟规则</SubTitle>
            <div class="item-content">
              <el-form-item label="库存展示：" prop="stockShow">
                <el-select
                  v-model="allianceInfo.stockShow"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in stockOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="价格展示：" prop="priceType">
                <el-select
                  v-model="allianceInfo.priceType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in priceTypeList"
                    :label="item.name"
                    :value="item.code"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="结算方式：" prop="settlementType">
                <el-select
                  v-model="allianceInfo.settlementType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in settlementTypeList"
                    :label="item.name"
                    :value="item.code"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发票类型：" prop="invoiceType">
                <el-select
                  v-model="allianceInfo.invoiceType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in invoiceTypeList"
                    :label="item.name"
                    :value="item.code"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账期：" prop="accountPeriod">
                <el-input
                  v-model="allianceInfo.accountPeriod"
                  clearable
                  style="width:50%;"
                  placeholder="账期"
                ></el-input>
                <span class="ml-8">月/单位</span>
              </el-form-item>
              <el-form-item label="对账日：" prop="reconciliationDay">
                <el-select
                  v-model="allianceInfo.reconciliationDay"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in reconciliationDayOptions"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-col>
        <el-col :span="13" :offset="1">
          <div class="grid-content">
            <SubTitle>联盟制度</SubTitle>
            <div class="item-content">
              <div class="edit-title">
                <span class="text-danger">*</span>
                联盟制度：默认为平台提供的制度范本，您可以根据需要进行修改。
              </div>
              <div class="edit">
                <Editor
                  :content="allianceInfo.institution"
                  :catchData="changeEditor"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import SubTitle from "@/components/manageCom/SubTitle.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import UploadFileImg from "@/components/Upload/UploadFileImg";
import Editor from "@/components/wangEditor/index.vue";
import { getDicts } from "@/api/commonService";

export default {
  name: "AllianceInfoEdit",
  components: {
    SubTitle,
    SelectArea,
    UploadFileImg,
    Editor,
  },
  // inject: ["allianceObj"],
  props: {
    allianceObj: {
      type: Object,
    },
  },
  data() {
    return {
      allianceInfo: {
        logoUrl: "",
        institution:
          "<h2>联盟管理制度</h2><p>&nbsp; &nbsp; &nbsp; 为了充分发挥联盟各成员相互之间合作的优势，促进联盟汽配集约化、网络化经营，提升联盟配件企业经营水平。根据国家有关法律、法规，经过相互平等友好协商，共同就结成联盟形成以下联盟管理制度：<br/></p><p>第一条&nbsp;&nbsp; &nbsp;&nbsp;联盟内共享库存在满足成员需求,并价格合适情况下，成员应在联盟内采购。</p><p>第二条&nbsp;&nbsp; &nbsp;&nbsp;平台价格为成员间交易上限，不得以缺货涨价等原因拒绝销售。</p><p>第三条&nbsp;&nbsp; &nbsp;&nbsp;平台经营配件不得以次充好，知假买假。如确实因采购时不慎出现货物假冒，提供进货凭证可酌情处理。</p><p>第四条&nbsp;&nbsp; &nbsp;&nbsp;平台默认每月15日为结帐期，超期不结账成员可以向管委会投诉。说明情况可适当延期并确认结算时间，再次逾期则给予处罚。</p><p>第五条&nbsp;&nbsp; &nbsp;&nbsp;平台成员上传库存需准确，不得以库存有误拒绝销售。</p><p>第六条&nbsp;&nbsp; &nbsp;&nbsp;一个月内成员间退货在无损不影响再次销售的情况下友好协商处理。</p><p>第七条&nbsp;&nbsp; &nbsp;&nbsp;因市场价格变动，成员应主动向管委会通报。</p><p>第八条&nbsp;&nbsp; &nbsp;&nbsp;成员不得泄露联盟库存及价格信息，一经发现罚款5000元，二次发生开除出联盟。</p><p>第九条&nbsp;&nbsp; &nbsp;&nbsp;联盟会议及集体活动提前通知，可提前请假。</p><p>第十条&nbsp;&nbsp; &nbsp;&nbsp;成员有义务对不良商户在联盟内通报。</p><p>成员违反上述制度者，以管委会处理决定为准，管委会拥有最终解释权。</p>",
      },
      imgList: [],
      rules: {
        name: [
          { required: true, message: "请输入联盟名称", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        stockShow: [
          { required: true, message: "请选择库存展示", trigger: "change" },
        ],
        priceType: [
          { required: true, message: "请选择价格展示", trigger: "change" },
        ],
        settlementType: [
          { required: true, message: "请选择结算方式", trigger: "change" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        accountPeriod: [
          { required: true, message: "请输入账期", trigger: "blur" },
        ],
        reconciliationDay: [
          {
            required: true,
            message: "请选择对账日",
            trigger: "change",
          },
        ],
      },
      stockOptions: [
        {
          value: "real",
          label: "真实库存数量",
        },
        {
          value: "status",
          label: "有货/无货",
        },
      ],
      priceTypeList: [],
      settlementTypeList: [],
      invoiceTypeList: [],
    };
  },
  computed: {
    reconciliationDayOptions() {
      let arr = [];
      for (let i = 0; i < 28; i++) {
        let obj = {
          value: i + 1,
          label: `${i + 1}`,
        };
        arr.push(obj);
      }
      return arr;
    },
  },
  watch: {
    imgList: {
      handler: function(newVal) {
        this.allianceInfo.logoUrl =
          newVal && newVal.length > 0 ? newVal[0].origin || newVal[0] : "";
      },
      deep: true,
    },
    allianceInfo: {
      handler(val) {
        this.$emit("change", val);
      },
      deep: true,
    },
    allianceObj: {
      handler(val) {
        console.log("val", val);
        if (val) {
          this.allianceInfo = { ...val };
          const { logoUrl } = val;
          if (logoUrl) {
            this.imgList = [
              {
                uid: logoUrl,
                url: logoUrl,
                origin: logoUrl,
              },
            ];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getPaymentTypeList(); //结算方式
    this.getInvoiceTypeList(); //发票类型
    this.getPriceTypeList(); //价格展示
  },
  mounted() {
    console.log("allianceObj", this.allianceObj);
  },
  methods: {
    getPaymentTypeList() {
      let params = {
        type: "settlementType",
      };
      getDicts(params)
        .then((res) => {
          this.settlementTypeList = res ? res : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInvoiceTypeList() {
      let params = {
        type: "invoiceType",
      };
      getDicts(params)
        .then((res) => {
          this.invoiceTypeList = res ? res : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPriceTypeList() {
      let params = {
        type: "priceType",
      };
      getDicts(params)
        .then((res) => {
          this.priceTypeList = res ? res : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    areaChange(areaObj) {
      this.allianceInfo.areaId = areaObj.areaId;
    },
    getImgFileList(fileList) {
      console.log("fileList", fileList);
      this.imgList = fileList;
    },
    changeEditor(data) {
      this.allianceInfo.institution = data;
      console.log(this.allianceInfo, 11122);
    },
    toCheck() {
      this.$refs["ruleForm"].validate((valid, err) => {
        if (valid) {
          this.$emit("check", true);
          return true;
        } else {
          const errTips = err[Object.keys(err)[0]][0].message;
          this.$message.warning({ message: errTips, showClose: true });
          this.$emit("check", false);

          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.allianceInfo {
  padding: 10px;
  .grid-content {
    padding: 10px 10px 0;
    .item-content {
      margin: 20px 0 10px;
      .edit {
        width: 100%;
        margin-top: 20px;
        /deep/#wangeditor {
          width: 100%;
          height: 680px;
        }
      }
    }
  }
}
</style>
